import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from '@apollo/client/core';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from '@apollo/client/cache';
import {
  ApolloProvider, ApolloLink, from, gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Spinner from './components/shared/spinner/Spinner';
import config from './helpers/config';
import './assets/scss/style.scss';

const httpLink = createHttpLink({ uri: config.API_URL });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('jwt_token');

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `JWT ${token}`,
      },
    };
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('jwt_token');
  if (!token && !window.location.toString().includes('authentication')) {
    window.location.href = '/authentication/login';
  }

  return forward(operation);
});

const typeDefs = gql`
  extend type OffersChainItemTypeInput {
    id :ID,
    startDate: Date,
    endDate: Date,
    offer: ID!,
  }
`;

const client = new ApolloClient({
  link: from([authMiddleware, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          offers: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          rates: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          propertyContacts: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          reservations: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          roomTypes: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          propertyProducts: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  typeDefs,
});

const App = lazy(
  () => new Promise((resolve) => {
    setTimeout(() => resolve(import('./app')), 0);
  }),
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </ApolloProvider>,
  document.getElementById('root'),
);
